define("discourse/plugins/chat/discourse/components/chat-drawer/thread", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse-i18n", "truth-helpers/helpers/and", "discourse/plugins/chat/discourse/components/chat-drawer/header", "discourse/plugins/chat/discourse/components/chat-drawer/header/back-link", "discourse/plugins/chat/discourse/components/chat-drawer/header/right-actions", "discourse/plugins/chat/discourse/components/chat-drawer/header/title", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _didInsert, _didUpdate, _service, _discourseI18n, _and, _header, _backLink, _rightActions, _title, _chatThread, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerThread = _exports.default = (_class = (_class2 = class ChatDrawerThread extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor4, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor5, this);
    }
    get backLink() {
      const link1 = {
        models: this.chat.activeChannel.routeModels
      };
      if (this.chatHistory.previousRoute?.name === "chat.channel.threads") {
        link1.title = _discourseI18n.default.t("chat.return_to_threads_list");
        link1.route = "chat.channel.threads";
      } else {
        link1.title = _discourseI18n.default.t("chat.return_to_channel");
        link1.route = "chat.channel";
      }
      return link1;
    }
    get threadTitle() {
      return this.chat.activeChannel?.activeThread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    fetchChannelAndThread() {
      if (!this.args.params?.channelId || !this.args.params?.threadId) {
        return;
      }
      return this.chatChannelsManager.find(this.args.params.channelId).then(channel1 => {
        this.chat.activeChannel = channel1;
        channel1.threadsManager.find(channel1.id, this.args.params.threadId).then(thread1 => {
          this.chat.activeChannel.activeThread = thread1;
        });
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ChatDrawerHeader @toggleExpand={{@drawerActions.toggleExpand}}>
        {{#if
          (and this.chatStateManager.isDrawerExpanded this.chat.activeChannel)
        }}
          <div class="chat-drawer-header__left-actions">
            <div class="chat-drawer-header__top-line">
              <ChatDrawerHeaderBackLink
                @route={{this.backLink.route}}
                @title={{this.backLink.title}}
                @routeModels={{this.backLink.models}}
              />
            </div>
          </div>
        {{/if}}
  
        <ChatDrawerHeaderTitle @translatedTitle={{this.threadTitle}} />
  
        <ChatDrawerHeaderRightActions @drawerActions={{@drawerActions}} />
      </ChatDrawerHeader>
  
      {{#if this.chatStateManager.isDrawerExpanded}}
        <div
          class="chat-drawer-content"
          {{didInsert this.fetchChannelAndThread}}
          {{didUpdate this.fetchChannelAndThread @params.channelId}}
          {{didUpdate this.fetchChannelAndThread @params.threadId}}
        >
          {{#if this.chat.activeChannel.activeThread}}
            <ChatThread
              @thread={{this.chat.activeChannel.activeThread}}
              @targetMessageId={{@params.messageId}}
            />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "Ymntl+sf",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@toggleExpand\"],[[30,1,[\"toggleExpand\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[30,0,[\"chat\",\"activeChannel\"]]],null],[[[1,\"        \"],[10,0],[14,0,\"chat-drawer-header__left-actions\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@route\",\"@title\",\"@routeModels\"],[[30,0,[\"backLink\",\"route\"]],[30,0,[\"backLink\",\"title\"]],[30,0,[\"backLink\",\"models\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,3],null,[[\"@translatedTitle\"],[[30,0,[\"threadTitle\"]]]],null],[1,\"\\n\\n      \"],[8,[32,4],null,[[\"@drawerActions\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,5],[[30,0,[\"fetchChannelAndThread\"]]],null],[4,[32,6],[[30,0,[\"fetchChannelAndThread\"]],[30,2,[\"channelId\"]]],null],[4,[32,6],[[30,0,[\"fetchChannelAndThread\"]],[30,2,[\"threadId\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\",\"activeThread\"]],[[[1,\"          \"],[8,[32,7],null,[[\"@thread\",\"@targetMessageId\"],[[30,0,[\"chat\",\"activeChannel\",\"activeThread\"]],[30,2,[\"messageId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@drawerActions\",\"@params\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/thread.js",
    "scope": () => [_header.default, _and.default, _backLink.default, _title.default, _rightActions.default, _didInsert.default, _didUpdate.default, _chatThread.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannelAndThread", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannelAndThread"), _class.prototype)), _class);
});